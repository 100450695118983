import React from "react";
import { navigate, RouteComponentProps } from "@reach/router";
import { PageHeader } from "ui/Header";
import Table from "ui/Table";
import axios from "axios";
import { Family } from "__types__";
import { FamilyColumns } from "./data";
import EditFamily from "./EditFamily";
import Paper from "ui/Paper";
import Deletion from "pages/Deletion";
import { InfoBarContext } from "contexts/InfoBarContext";
import ActionsGroup from "ui/ActionsGroup";

export interface DefaultPageProps extends RouteComponentProps {
  noPageTitle?: boolean;
}

const actions = [
  { key: "activate", label: "Activer", abilities: ["ADMIN", "MANAGER"] },
  { key: "desactivate", label: "Désactiver", abilities: ["ADMIN", "MANAGER"] },
  {
    key: "resend-email",
    label: "Renvoyer le mail d'activation",
    abilities: ["ADMIN", "MANAGER"],
  },
];

export const Home: React.FC<DefaultPageProps> = () => {
  const [data, setData] = React.useState<Family[]>([]);
  const [open, setOpen] = React.useState(false);
  const [current, setCurrent] = React.useState(null);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const { setInfo } = React.useContext(InfoBarContext);

  React.useEffect(() => {
    axios("/api/v1/families").then((res) => {
      setData(res.data);
    });
  }, []);

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleAction = (actionKey: string) => {
    switch (actionKey) {
      case "activate":
        return activate();
      case "desactivate":
        return desactivate();
      case "resend-email":
        return resendActivationEmail();
    }
  };

  const resendActivationEmail = () => {
    if (selectedRows.length <= 0)
      return setInfo({
        type: "warning",
        message: "Selectionner les éléments",
      });

    if (selectedRows.length > 10) {
      return setInfo({
        type: "warning",
        message: "Veuillez sélectionner un maximum de 10 familles",
      });
    }

    axios
      .post("/api/v1/families/resend-activation-email", {
        data: selectedRows,
      })
      .then((res) => {
        setInfo({
          type: "success",
          message: "Email renvoyé avec succès",
        });
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      });
  };

  const activate = () => {
    if (selectedRows.length <= 0)
      return setInfo({
        type: "warning",
        message: "Selectionner les éléments",
      });

    axios
      .put("/api/v1/families/activate", {
        data: selectedRows,
      })
      .then((res) => {
        setInfo({
          type: "success",
          message: "Élements activé avec succès",
        });
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      });
  };

  const desactivate = () => {
    if (selectedRows.length <= 0)
      return setInfo({
        type: "warning",
        message: "Selectionner les éléments",
      });

    axios
      .put("/api/v1/families/desactivate", {
        data: selectedRows,
      })
      .then((res) => {
        setInfo({
          type: "success",
          message: "Élements désactivé avec succès",
        });
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      });
  };

  return (
    <>
      <PageHeader title={`Familles (${data.length})`}>
        {/* <Can abilities={["ADMIN", "MANAGER"]}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              setOpen(true)
            }}
          >
            Ajouter
        </Button>
        </Can> */}
        <ActionsGroup onClick={handleAction} actions={actions} />
      </PageHeader>

      <Paper>
        <Table
          hasSelection
          rowsIdentifier="id"
          columns={FamilyColumns}
          data={data}
          onRowClick={(row) => {
            navigate("/soutien-scolaire/familles/" + row.code);
          }}
          onRowDelete={(row) => {
            setCurrent(row);
            setOpenDelete(true);
          }}
          onRowChecked={setSelectedRows}
        />
      </Paper>

      <EditFamily open={open} onClose={() => setOpen(false)} />

      <Deletion
        open={openDelete}
        onClose={handleCloseDelete}
        current={current}
        path="/api/v1/families/"
      />
    </>
  );
};

export default Home;
