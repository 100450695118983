import React from "react";
import { navigate, RouteComponentProps } from "@reach/router";
import Table from "ui/Table";
import { PageHeader } from "ui/Header";
import { Candidat } from "__types__";
import { CandidatsColumns } from "./data";
import Paper from "ui/Paper";
import axios from "axios";
import "styled-components/macro";
import Deletion from "pages/Deletion";
import { InfoBarContext } from "contexts/InfoBarContext";
import ActionsGroup from "ui/ActionsGroup";
export interface DefaultPageProps extends RouteComponentProps {
  noPageTitle?: boolean;
}

const actions = [
  { key: "activate", label: "Activer", abilities: ["ADMIN", "MANAGER"] },
  { key: "desactivate", label: "Désactiver", abilities: ["ADMIN", "MANAGER"] },
  {
    key: "resend-email",
    label: "Renvoyer le mail d'activation",
    abilities: ["ADMIN", "MANAGER"],
  },
];

export const Candidats: React.FC<DefaultPageProps> = () => {
  const [data, setData] = React.useState<Candidat[]>([]);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [current, setCurrent] = React.useState(null);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const { setInfo } = React.useContext(InfoBarContext);

  React.useEffect(() => {
    axios("/api/v1/candidats").then((res) => {
      setData(res.data);
    });
  }, []);

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleAction = (actionKey: string) => {
    switch (actionKey) {
      case "activate":
        return activate();
      case "desactivate":
        return desactivate();
      case "resend-email":
        return resendActivationEmail();
    }
  };

  const resendActivationEmail = () => {
    if (selectedRows.length <= 0)
      return setInfo({
        type: "warning",
        message: "Selectionner les éléments",
      });

    if (selectedRows.length > 10) {
      return setInfo({
        type: "warning",
        message: "Veuillez sélectionner un maximum de 10 candidats",
      });
    }

    axios
      .post("/api/v1/candidats/resend-activation-email", {
        data: selectedRows,
      })
      .then((res) => {
        setInfo({
          type: "success",
          message: "Email renvoyé avec succès",
        });
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      });
  };

  const activate = () => {
    if (selectedRows.length <= 0)
      return setInfo({
        type: "warning",
        message: "Selectionner les éléments",
      });

    axios
      .put("/api/v1/candidats/activate", {
        data: selectedRows,
      })
      .then((res) => {
        setInfo({
          type: "success",
          message: "Élements activé avec succès",
        });
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      });
  };

  const desactivate = () => {
    if (selectedRows.length <= 0)
      return setInfo({
        type: "warning",
        message: "Selectionner les éléments",
      });

    axios
      .put("/api/v1/candidats/desactivate", {
        data: selectedRows,
      })
      .then((res) => {
        setInfo({
          type: "success",
          message: "Élements désactivé avec succès",
        });
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      });
  };

  return (
    <>
      <PageHeader title={`Candidats (${data?.length})`}>
        <ActionsGroup onClick={handleAction} actions={actions} />
      </PageHeader>
      <Paper>
        <Table
          hasSelection
          rowsIdentifier="id"
          columns={CandidatsColumns}
          data={data}
          onRowClick={(row) => {
            navigate("/emplois/candidats/" + row.id);
          }}
          onRowDelete={(row) => {
            setCurrent(row);
            setOpenDelete(true);
          }}
          onRowChecked={setSelectedRows}
        />
      </Paper>

      <Deletion
        open={openDelete}
        onClose={handleCloseDelete}
        current={current}
        path="/api/v1/candidats/"
      />
    </>
  );
};

export default Candidats;
